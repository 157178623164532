<template>
  <v-responsive :width="width" :height="height" :max-height="maxHeight" :min-height="minHeight" :max-width="maxWidth"
    :min-width="minWidth" :aspect-ratio="computedAspectRatio" class="dy-img">
    <!-- min-height를 최소 1이라도 줘야 버그 없이 작동함. 아예 영역을 못잡고 있으면 Intersection Observer가 동작하지 않을 수도 있음 -->
    <v-lazy v-if="isHuman" :min-height="1" transition="fade-transition" @update:model-value="onLoadStart">
      <img ref="img" :class="imgClass" :src="src" :srcset="srcset" :sizes="computedSizes" :alt="alt" @error="onError"
        @load="onLoad" />
    </v-lazy>
    <img v-else ref="img" :class="imgClass" :src="src" :srcset="srcset" :sizes="computedSizes" :alt="alt" @error="onError"
      @load="onLoad" />

    <v-lazy :min-height="1" transition="fade-transition">
      <div v-if="isLoading && isHuman" class="absolute h-full w-full flex justify-center items-center"
        :class="autoBackgroundColor ? computedAutoBackgroundColor : ''">
        <slot name="placeholder">
          <v-progress-circular color="grey-lighten-4" indeterminate />
        </slot>
      </div>
    </v-lazy>
    
    <div class="absolute h-full w-full top-0">
      <slot />
    </div>
  </v-responsive>
</template>
<script setup lang="ts">
import { random } from 'lodash-es';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

library.add(faTimes)

const props = withDefaults(defineProps<{
  src: string
  srcset?: string
  width?: string | number
  height?: string | number
  maxHeight?: string | number
  minHeight?: string | number
  maxWidth?: string | number
  minWidth?: string | number
  aspectRatio?: string | number
  cover?: boolean
  alt?: string
  sizes?: string
  autoBackgroundColor?: boolean
}>(), {
  cover: () => false,
  autoBackgroundColor: () => false
})

const emit = defineEmits<{
  (e: 'error', value: Event): void
  (e: 'load', value: Event): void
  (e: 'loadstart'): void
}>()

const { isHuman } = useDYUserStore()
const windowStore = useDYWindowStore()

const img = ref<HTMLImageElement>()
const naturalWidth = ref(0)
const naturalHeight = ref(0)
const isLoading = ref(true)

const computedAutoBackgroundColor = computed(() => {
  const randomColor = [
    'bg-gradient-to-r from-indigo-200 to-rose-200',
    'bg-gradient-to-r from-cyan-200 to-blue-200',
    'bg-gradient-to-r from-indigo-200 to-pink-200',
    'bg-gradient-to-r from-green-200 to-fuchsia-200',
    'bg-gradient-to-r from-purple-200 to-violet-200',
    'bg-gradient-to-r from-red-200 to-blue-200',
    'bg-gradient-to-r from-yellow-200 to-sky-200',
    'bg-gradient-to-r from-stone-200 to-teal-200',
    'bg-gradient-to-r from-orange-200 to-emerald-200',
    'bg-gradient-to-r from-amber-200 to-lime-200',
    'bg-gradient-to-r from-lime-200 to-stone-200',
    'bg-gradient-to-r from-emerald-200 to-rose-200',
    'bg-gradient-to-r from-teal-200 to-lime-200',
    'bg-gradient-to-r from-sky-200 to-orange-200',
    'bg-gradient-to-r from-blue-200 to-yellow-200',
    'bg-gradient-to-r from-violet-200 to-red-200',
    'bg-gradient-to-r from-fuchsia-200 to-green-200',
    'bg-gradient-to-r from-pink-200 to-cyan-200',
    'bg-gradient-to-r from-rose-200 to-indigo-200',
  ]

  if (props.autoBackgroundColor) return randomColor[random(0, randomColor.length - 1)]
  return ''
})

const imgClass = computed(() => {
  const classes = []
  if (props.cover) {
    classes.push('object-cover')
  } else classes.push('object-contain')

  return classes.join(' ')
})

const computedSizes = computed(() => {
  if (props.sizes) {
    return props.sizes
  }

  function makeSizes(value?: string | number) {
    if (!value) return

    if (typeof value === 'string' && /[0-9]+(px)*/.test(value)) {
      return value
    } else if (typeof value === 'number' || /[0-9]+/.test(value)) {
      return `${value}px`
    } else {
      return undefined
    }
  }
  if(windowStore.windowWidth > 577){
    return makeSizes(props.width) ?? makeSizes(props.maxWidth)
  }else{
    return makeSizes(props.width) ?? makeSizes(props.maxWidth) ?? makeSizes(props.height) ?? makeSizes(props.maxHeight)
  }
  
})

const computedAspectRatio = computed(() => {
  if (props.aspectRatio) {
    return props.aspectRatio
  }
  if (img.value && naturalWidth.value && naturalHeight.value) {
    return naturalWidth.value / naturalHeight.value
  }
  return undefined
})

const onLoadStart = (value: boolean) => {
  if (value) {
    isLoading.value = true
    emit('loadstart')
  }
}

const onLoad = (event: Event) => {
  if (img.value) {
    naturalWidth.value = img.value.naturalWidth
    naturalHeight.value = img.value.naturalHeight
  }

  isLoading.value = false
  emit('load', event)
}

const onError = (event: Event) => {
  isLoading.value = false
  emit('error', event)
}

</script>

<style scoped>
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>